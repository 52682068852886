import React from 'react'

const Card = ({ name, designation, avatar, isOnTop }) => {
  return (
    <div
      className="shadow-lg rounded-2xl cursor-pointer h-96 w-full bg-cover bg-no-repeat bg-center text-white p-4 flex flex-col-reverse absolute"
      style={{
        transition: 'top .5s, transform .5s',
        backgroundImage: `url(${avatar}`,
        top: isOnTop ? 40 : 0,
        transform: isOnTop ? 'scale(1.1)' : 0,
      }}
    >
      <div className="rounded-2xl p-4 flex flex-col-reverse absolute w-full h-32 from-black/70 bg-gradient-to-t left-0 bottom-0">
        <div className="">{designation}</div>
        <div className="font-bold text-2xl">{name}</div>
      </div>
    </div>
  )
}

export default Card
