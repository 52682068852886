import React, { useRef, useState, useMemo } from 'react';
import TinderCard from 'react-tinder-card';
import Card from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faHeart,
  faStar,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';

const SwipeLeft = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      className="bg-white cursor-pointer text-orange-400 rounded-full shadow-md font-bold w-20 h-20 flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faXmark} fontWeight="bold" size="2xl" />
    </span>
  );
};

const SwipeRight = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      className="bg-white cursor-pointer text-red-500 rounded-full shadow-md font-bold w-20 h-20 flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faHeart} fontWeight="bold" size="2xl" />
    </span>
  );
};

const SuperLike = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      className="text-white cursor-pointer bg-purple-700 rounded-full shadow-md font-bold w-24 h-24 flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faStar} fontWeight="bold" size="2xl" />
    </span>
  );
};

const peers = [
  {
    id: 1,
    name: 'Jared Dunn',
    title: 'Chief Operating Officer',
    avatar: '/avatars/jared_crop.jpg',
  },
  {
    id: 3,
    name: 'Erlich Bachman',
    title: 'Owns 10 percent',
    avatar: '/avatars/erlich_crop.jpg',
  },
  {
    id: 4,
    name: 'Monica',
    title: 'Board member',
    avatar: '/avatars/monica.jpg',
  },
  {
    id: 5,
    name: 'Dinesh Chugtai',
    title: 'Asst. Chief Technology Officer',
    avatar: '/avatars/dinesh_crop.jpg',
  },
  {
    id: 2,
    name: 'Gilfoyle',
    title: 'Chief Technology Officer',
    avatar: '/avatars/gilfoyle_crop.jpg',
  },
  {
    id: 6,
    name: 'Richard Hendricks',
    title: 'CEO',
    avatar: '/avatars/richard_crop.jpg',
  },
];

const actionMap = {
  up: 'super liked',
  left: "didn't like",
  right: 'liked',
};

function App() {
  const [currentIndex, setCurrentIndex] = useState(peers.length - 1);
  const [lastDirection, setLastDirection] = useState();
  const [lastPeer, setLastPeer] = useState();
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(peers.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canGoBack = currentIndex < peers.length - 1;

  const canSwipe = currentIndex >= 0;

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < peers.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const handleSwipe = (dir, name, index) => {
    setLastDirection(dir);
    setLastPeer(name);
    updateCurrentIndex(index - 1);
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="max-w-xs p-4">
        <div className="mb-8 font-bold text-2xl">Like working with them?</div>
        <div className="relative" style={{ height: '30rem' }}>
          {peers.map((peer, idx) => (
            <TinderCard
              ref={childRefs[idx]}
              key={peer.id}
              onSwipe={(dir) => handleSwipe(dir, peer.name, idx)}
            >
              <Card
                isOnTop={idx === currentIndex}
                name={peer.name}
                designation={peer.title}
                avatar={peer.avatar}
              />
            </TinderCard>
          ))}
          {currentIndex === -1 && <div>Thank you, now get back to work</div>}
        </div>
        {lastDirection && (
          <div>
            You {actionMap[lastDirection]} {lastPeer}
            <button onClick={goBack} className="ml-2 text-yellow-400 rounded-full w-8 h-8 bg-yellow-100">
              <FontAwesomeIcon icon={faUndo} />
            </button>
          </div>
        )}
        <div className="mt-10 flex gap-4 items-center">
          <SwipeLeft onClick={() => swipe('left')} />
          <SuperLike onClick={() => swipe('up')} />
          <SwipeRight onClick={() => swipe('right')} />
        </div>
      </div>
    </div>
  );
}

export default App;
